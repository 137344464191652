<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo de Resumen de Venta - Listar</strong>
          </CCardHeader>
          <CCardBody>
                <b-tabs content-class="mt-3">
                  <b-tab title="RC - Boletas" active>

                    <b-row>
                      <b-col sm="12" md="5">
                       
                      </b-col>

                      <b-col sm="12" md="2">
                        <b-form-group label="Desde :">
                          <b-form-input @change="ListSalesRC" class="text-center" :max="to_rc" type="date"  ref="to" v-model="from_rc"></b-form-input>
                          <small v-if="errors_rc.from" class="form-text text-danger" >Seleccione una fecha</small>
                        </b-form-group>
                      </b-col>

                      <b-col sm="12" md="2">
                        <b-form-group label="Hasta :">
                          <b-form-input @change="ListSalesRC" class="text-center" :min="from_rc" type="date"  ref="from" v-model="to_rc"></b-form-input>
                          <small v-if="errors_rc.to" class="form-text text-danger" >Seleccione una fecha</small>
                        </b-form-group>
                      </b-col>
                      
                      <b-col sm="6" md="2">
                        <b-form-group label=".">
                          <b-input-group>
                          <b-form-input v-model="search_rc" class="form-control"></b-form-input>
                          <b-input-group-append>
                            <b-button @click="ListSalesRC" variant="primary" ><b-icon icon="search"></b-icon></b-button>
                          </b-input-group-append>
                        </b-input-group>
                        </b-form-group>
                      </b-col>

                      <b-col sm="6" md="1">
                        <b-form-group label=".">
                          <b-button @click="ModalRCShow" class="form-control" type="button" variant="primary"><i class="fas fa-file"></i></b-button>
                        </b-form-group>
                      </b-col>
                
                    </b-row>
                      <div class="table-responsive mt-3 height-table-sale">
                        <table class="table table-hover table-bordered">
                          <thead>
                            <tr>
                              <th width="3%" class="text-center">#</th>
                              <th width="9%" class="text-center">Fecha Comp.</th>
                              <th width="10%" class="text-center">Comprobante</th>
                              <th width="9%" class="text-center">Fecha Ref.</th>
                              <th width="50%" class="text-center">Documentos de Referencia</th>
                               <th width="8%" class="text-center">Tipo</th>
                              <th width="8%" class="text-center">Estado</th>
                              <th width="7%" class="text-center">Acciones</th>
                            </tr>
                          </thead>
                          <tbody v-for="(item, it) in data_table_rc" :key="it">
                            <tr>
                              <td class="text-center">{{ it + 1 }}</td>
                              <td class="text-center"> {{ item.low_date  }}</td>
                              <td class="text-center"> {{ item.code + " "+item.serie+"-"+item.sequence  }}</td>
                              <td class="text-center"> {{ item.reference_date  }}</td>
                              <td class="text-left"> {{ item.reference  }}</td>
                              <td class="text-center">
                                    <b-badge v-if="item.state_sale == 1" variant="primary">Envio por RC</b-badge>
                                    <b-badge v-if="item.state_sale == 3" variant="primary">Anulación por RC</b-badge>
                              </td>
                              <td class="text-center">
                                    <b-badge v-if="item.state == 1" variant="danger">XML</b-badge>
                                    <b-badge v-if="item.state == 2" variant="info">Emitido</b-badge>
                                    <b-badge v-if="item.state == 3" :variant="item.cod_sunat_ticket == '0' ? 'success':'warning'">Aceptado</b-badge>
                                    <b-badge v-if="item.state == 4" variant="danger">Rechazado</b-badge>
                              </td>
                              <td class="text-center">
                                <b-dropdown bloque size="sm" text="Acciones" right>
                                  <b-dropdown-item v-if="Permission('SaleSummaryEdit') && (item.state == 2 || item.state == 4)" @click="SendXML(item.id_sale_low)">Enviar XML</b-dropdown-item>
                                  <b-dropdown-item v-if="Permission('SaleSummaryEdit') && item.state == 3 && item.cod_sunat_ticket != '0'" @click="SendXML(item.id_sale_low)">Enviar XML</b-dropdown-item>
                                  <b-dropdown-item v-if="Permission('SaleSummaryEdit') && (item.state == 3 || item.state == 4)"  @click="ModalCPESunatShow(item.id_sale_low)">Ver CPE</b-dropdown-item>
                                  <b-dropdown-item v-if="Permission('SaleSummaryEdit') && (item.state == 3 || item.state == 4) && item.cod_sunat_ticket != '0'"  @click="ConsultTicket(item.id_sale_low)">Consultar Ticket</b-dropdown-item>
                                  <b-dropdown-item v-if="Permission('SaleSummaryEdit')"  @click="ModalRCDetailShow(item.id_sale_low)">Ver Detalle</b-dropdown-item>
                                  
                                </b-dropdown>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <b-row class="mt-4">
                        <b-col md="8">
                          <b-pagination v-model="currentPage_rc" v-on:input="ListSalesRC" :total-rows="rows_rc" :per-page="perPage_rc" align="center"></b-pagination>
                        </b-col>
                        <b-col md="4 text-center">
                          <p>Pagina Actual: {{ currentPage_rc }}</p>
                        </b-col>
                      </b-row>
                  </b-tab>
                  <b-tab title="RA - Facturas">
                    <b-row>
                      <b-col sm="12" md="6">
                       
                      </b-col>

                      <b-col sm="12" md="2">
                        <b-form-group label="Desde :">
                          <b-form-input @change="ListSalesRA" class="text-center" :max="to_ra" type="date"  ref="to" v-model="from_ra"></b-form-input>
                          <small v-if="errors_rc.from" class="form-text text-danger" >Seleccione una fecha</small>
                        </b-form-group>
                      </b-col>

                      <b-col sm="12" md="2">
                        <b-form-group label="Hasta :">
                          <b-form-input @change="ListSalesRA" class="text-center" :min="from_ra" type="date"  ref="from" v-model="to_ra"></b-form-input>
                          <small v-if="errors_rc.to" class="form-text text-danger" >Seleccione una fecha</small>
                        </b-form-group>
                      </b-col>
                      
                      <b-col sm="6" md="2">
                        <b-form-group label=".">
                          <b-input-group>
                          <b-form-input v-model="search_ra" class="form-control"></b-form-input>
                          <b-input-group-append>
                            <b-button @click="ListSalesRA" variant="primary" ><b-icon icon="search"></b-icon></b-button>
                          </b-input-group-append>
                        </b-input-group>
                        </b-form-group>
                      </b-col>

                      <!-- <b-col sm="6" md="1">
                        <b-form-group label=".">
                          <b-button @click="ModalRAShow" class="form-control" type="button" variant="primary"><i class="fas fa-file"></i></b-button>
                        </b-form-group>
                      </b-col> -->
                
                    </b-row>
                      <div class="table-responsive mt-3 height-table-sale">
                        <table class="table table-hover table-bordered">
                          <thead>
                            <tr>
                              <th width="3%" class="text-center">#</th>
                              <th width="9%" class="text-center">Fecha Comp.</th>
                              <th width="10%" class="text-center">Comprobante</th>
                              <th width="9%" class="text-center">Fecha Ref.</th>
                              <th width="50%" class="text-center">Documentos de Referencia</th>
                               <th width="8%" class="text-center">Tipo</th>
                              <th width="8%" class="text-center">Estado</th>
                              <th width="7%" class="text-center">Acciones</th>
                            </tr>
                          </thead>
                          <tbody v-for="(item, it) in data_table_ra" :key="it">
                            <tr>
                              <td class="text-center">{{ it + 1 }}</td>
                              <td class="text-center"> {{ item.low_date  }}</td>
                              <td class="text-center"> {{ item.code + " "+item.serie+"-"+item.sequence  }}</td>
                              <td class="text-center"> {{ item.reference_date  }}</td>
                              <td class="text-left"> {{ item.reference  }}</td>
                              <td class="text-center">
                                    <b-badge v-if="item.state_sale == 1" variant="primary">Envio por RC</b-badge>
                                    <b-badge v-if="item.state_sale == 3" variant="primary">Anulación por RC</b-badge>
                              </td>
                              <td class="text-center">
                                    <b-badge v-if="item.state == 1" variant="danger">XML</b-badge>
                                    <b-badge v-if="item.state == 2" variant="info">Emitido</b-badge>
                                    <b-badge v-if="item.state == 3" :variant="item.cod_sunat_ticket == '0' ? 'success':'warning'">Aceptado</b-badge>
                                    <b-badge v-if="item.state == 4" variant="danger">Rechazado</b-badge>
                              </td>
                              <td class="text-center">
                                <b-dropdown bloque size="sm" text="Acciones" right>
                                  <b-dropdown-item v-if="Permission('SaleSummaryEdit') && (item.state == 2 || item.state == 4)" @click="SendXML(item.id_sale_low)">Enviar XML</b-dropdown-item>
                                  <b-dropdown-item v-if="Permission('SaleSummaryEdit') && item.state == 3 && item.cod_sunat_ticket != '0'" @click="SendXML(item.id_sale_low)">Enviar XML</b-dropdown-item>
                                  <b-dropdown-item v-if="Permission('SaleSummaryEdit') && (item.state == 3 || item.state == 4)"  @click="ModalCPESunatShow(item.id_sale_low)">Ver CPE</b-dropdown-item>
                                  <b-dropdown-item v-if="Permission('SaleSummaryEdit') && (item.state == 3 || item.state == 4) && item.cod_sunat_ticket != '0'"  @click="ConsultTicket(item.id_sale_low)">Consultar Ticket</b-dropdown-item>
                                  <b-dropdown-item v-if="Permission('SaleSummaryEdit')"  @click="ModalRADetailShow(item.id_sale_low)">Ver Detalle</b-dropdown-item>
                                  
                                </b-dropdown>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <b-row class="mt-4">
                        <b-col md="8">
                          <b-pagination v-model="currentPage_rc" v-on:input="ListSalesRC" :total-rows="rows_rc" :per-page="perPage_rc" align="center"></b-pagination>
                        </b-col>
                        <b-col md="4 text-center">
                          <p>Pagina Actual: {{ currentPage_rc }}</p>
                        </b-col>
                      </b-row>
                  </b-tab>
                </b-tabs>

          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <LoadingComponent :is-visible="isLoading"/>
    <ModalCPESunat/>

    <ModalRC/>
    <ModalRCDetail/>
    <ModalRA/>
    <ModalRADetail/>
  </div>
</template>
<style>
.height-table-sale{
  min-height: 280px !important;
}
</style>
<script>
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
import CodeToName from "@/assets/js/CodeToName";
var moment = require("moment");
import EventBus from "@/assets/js/EventBus";
import LoadingComponent from './../pages/Loading'
import ModalCPESunat from './components/ModalCPESunat'

import ModalRC from './components/ModalRC'
import ModalRCDetail from './components/ModalRCDetail'

import ModalRA from './components/ModalRA'
import ModalRADetail from './components/ModalRADetail'


export default {
  name: "UsuarioList",
  components:{
    vSelect,
    LoadingComponent,
    ModalCPESunat,
    ModalRC,
    ModalRCDetail,
    ModalRA,
    ModalRADetail,
  },
  data() {
    return {
      isLoading:false,
      module: 'SaleSummary',
      role_rc:1,
      perPage_rc: 15,
      currentPage_rc: 1,
      rows_rc: 0,
      data_table_rc: [],
      to_rc:moment(new Date()).local().format("YYYY-MM-DD"),
      from_rc:moment().subtract(7, 'days').local().format("YYYY-MM-DD"),
      search_rc: "",
      errors_rc:{
        to:false,
        from:false,
      },


      role_ra:1,
      perPage_ra: 15,
      currentPage_ra: 1,
      rows_ra: 0,
      data_table_ra: [],
      to_ra:moment(new Date()).local().format("YYYY-MM-DD"),
      from_ra:moment().subtract(7, 'days').local().format("YYYY-MM-DD"),
      search_ra: "",
      errors_ra:{
        to:false,
        from:false,
      },

    
      

      sale_low: {
        id_sale_low : '',
        id_sale : '',
        id_user : '',
        code : '',
        serie : '',
        sequence : '',
        reference_date : '',
        low_date : '',
        reason : '',
        sunat_message : '',
        observation:'',
        state:1,
      },
      errors_low:{
          reason:false,
      },
    };
  },
  mounted() {
    this.ListSalesRC();
    this.ListSalesRA();

    EventBus.$on('RefreshListSalesRC', () => {
      this.ListSalesRC();
    });
  },
  methods: {
    ModalRCShow,
    ListSalesRC,
    ModalRCDetailShow,
    ///facturas
    ModalRAShow,
    ListSalesRA,
    ModalRADetailShow,

    SendXML,
    Permission,
    ConsultTicket,
    ModalCPESunatShow,


  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
    id_establishment: function () {
      let id_establishment = window.localStorage.getItem("id_establishment");
      id_establishment = JSON.parse(je.decrypt(id_establishment));
      return id_establishment;
    },
  },
};

function ModalRAShow() {
  EventBus.$emit('ModalRAShow');
}

function ModalRADetailShow(id_sale_low) {
  EventBus.$emit('ModalRADetailShow',id_sale_low);
}

function ListSalesRA() {
  
  this.errors_rc.from = false;
  this.errors_rc.to = false;

  if (this.from_rc.length == 0) {this.errors_rc.from = true; return false;}
  if (this.to_rc.length == 0) {this.errors_rc.to = true; return false;}
  let search = this.search_rc == "" ? "all" : this.search_rc;
  let me = this;
  let url = this.url_base + "sale-summary/list/" + this.from_rc + "/" + this.to_rc + "/" + this.id_establishment + "/RA/" + search + "?page=" + this.currentPage_rc;
  axios({
    method: "GET",
    url: url,
    headers: { token: this.token, module: this.module, role: 1},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.rows_ra = response.data.result.total;
        me.data_table_ra = response.data.result.data;
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}







function ModalRCShow() {
  EventBus.$emit('ModalRCShow');
}

function ModalCPESunatShow(id_sale_low) {
  EventBus.$emit('ModalCPESunatShow',id_sale_low);
}

function ModalRCDetailShow(id_sale_low) {
  EventBus.$emit('ModalRCDetailShow',id_sale_low);
}

function ConsultTicket(id_sale_low) {
  this.isLoading = true;
  let me = this;
  let url = this.url_base + "sale-summary/consult-ticket/" + id_sale_low;
  axios({
    method: "GET",
    url: url,
    headers: { token: this.token, module: this.module,role: 1 },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        for (var i = 0; i < me.data_table_rc.length; i++) {
          if (me.data_table_rc[i].id_sale_low == id_sale_low) {
            me.data_table_rc[i].state = response.data.result.state;
            me.data_table_rc[i].cod_sunat_ticket = response.data.result.cod_sunat_ticket;
            break;
          }
        }
        if (response.data.result.state == 3) {
          Swal.fire({ icon: 'success', text: response.data.result.sunat_message, timer: 3000,})
        }else{
          Swal.fire({ icon: 'error', text: response.data.result.sunat_message})
        }
        
      } else {
        Swal.fire({ icon: 'error', text: response.data.result.message, timer: 3000,})
      }
      me.isLoading = false;
    })
}



function ListSalesRC() {
  
  this.errors_rc.from = false;
  this.errors_rc.to = false;

  if (this.from_rc.length == 0) {this.errors_rc.from = true; return false;}
  if (this.to_rc.length == 0) {this.errors_rc.to = true; return false;}
  let search = this.search_rc == "" ? "all" : this.search_rc;
  let me = this;
  let url = this.url_base + "sale-summary/list/" + this.from_rc + "/" + this.to_rc + "/" + this.id_establishment + "/RC/" + search + "?page=" + this.currentPage_rc;
  axios({
    method: "GET",
    url: url,
    headers: { token: this.token, module: this.module, role: 1},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.rows_rc = response.data.result.total;
        me.data_table_rc = response.data.result.data;
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

function SendXML(id_sale_low) {
  this.isLoading = true;
  let me = this;
  let url = this.url_base + "sale-summary/send-xml/" + id_sale_low;
  axios({
    method: "get",
    url: url,
    headers: { token: this.token, module: this.module,role: 1 },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        for (var i = 0; i < me.data_table_rc.length; i++) {
          if (me.data_table_rc[i].id_sale_low == id_sale_low) {
            me.data_table_rc[i].state = response.data.result.state;
            me.data_table_rc[i].cod_sunat_ticket = response.data.result.cod_sunat_ticket;
            break;
          }
        }
        if (response.data.result.state == 3) {
          Swal.fire({ icon: 'success', text: 'El comprobante, ha sido aceptado', timer: 3000,})
        }else if(response.data.result.state == 5){
          Swal.fire({ icon: 'error', text: response.data.result.sunat_message})
        }
        
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
      me.isLoading = false;
    })
}


// permisos
function Permission(module_permission) {
  let user_permissions = window.localStorage.getItem("user_permissions");
  user_permissions = JSON.parse(JSON.parse(je.decrypt(user_permissions)));
  if (user_permissions.indexOf(module_permission) > -1) {
    return true;
  } else {
    return false;
  }
}
</script>
